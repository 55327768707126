import * as React from "react";
import loadable from '@loadable/component'
import BenefitsList from "../components/benefits-list/benefitsList";

import {
  landingGuide,
  fullParagraphContent,
} from "../utils/informations";

const Layout = loadable(() => import("../layouts"))
const Header = loadable(() => import("../components/homepage-header/homepage-header"))
const Insurance = loadable(() => import("../components/homepage-insurances/homepage-insurances"))
const FullScreenParagraph = loadable(() => import("../components/fullscreen-paragraph/fullscreen-paragraph"))



const IndexPage = () => (

  <Layout pageTitle="Compare Warranty Insurance">

    <Header type="homepage"/>
    <Insurance />
    <FullScreenParagraph props={fullParagraphContent} origin="Star" />
    <BenefitsList props={landingGuide}></BenefitsList>

  </Layout>
  
);

export default IndexPage;
