import React from "react";
import "./benefitsList.scss";
import checked_icon from "../../assets/checkedIconGreen.webp";
import { StaticImage } from "gatsby-plugin-image"
import { MyTheme } from "../../utils/informations"


const BenefitsList = ({ props }) => {
    return (

        <div className="benefit-container" id="landing-guide">

            <div className="benefit-content">
                <div className="benefit-title">
                    <h2>{props.title}</h2>
                </div>
                <div className="benefit-list">
                    {
                        props.benefits ? props.benefits.map((benefit, index) => {
                            return (
                                <div className="benefit" key={index}>
                                    <div className="benefit-flex">
                                        <img src={checked_icon} alt="product-benefits"></img>
                                        <p>{benefit.title}</p>
                                    </div>
                                    {
                                        benefit.description ?
                                            <p>{benefit.description}</p>
                                            : ""
                                    }
                                    {
                                        benefit.title === "Labour rate" ?
                                            <div>
                                                <p>On this comparison site you are offered a choice of labour rates and you are free to choose your own dealer or use one of the ones approved by the administrator. </p>
                                                <p>£42.50 – This may be suitable for older vehicles only and where you will not be using main dealers.</p>
                                                <p>£70.00 – This may be suitable for slightly newer vehicles and is a rate offered by most chain service and repair companies or local repairers.</p>
                                                <p>£100.00 – This may be suitable for nearly new vehicles and may allow you to use some main dealers for repairs.</p>
                                                <p style={{ fontWeight: "600", marginTop: '15px' }}>We recommend that you check the current labour rate with your normal local garage that you currently use for servicing or repairs</p>
                                                <p style={{ fontWeight: '600', marginTop: '15px' }}>If you select a product that does not provide the labour rates required by the repairing garage, part of any claim you make may not be covered</p>
                                            </div>
                                            : ""
                                    }
                                    {
                                        benefit.title === "Wear and Tear and Boost Upgrade" ?
                                            <div id="wear-and-tear">
                                               <p> <b>Wear and Tear</b> is damage that naturally and inevitably occurs as a result of normal wearing and aging. Some warranty providers offer this cover (at an additional cost), and some do not offer it. Check to see when Wear and Tear cover starts and also when it might end - based on the vehicle age and mileage.</p>
                                               <p> <b>Boost</b> may upgrade your level of cover to include failure of some / all of the following Parts: Catalytic Convertor, Multimedia / Sound Equipment, Diesel Particulate Filter, Battery and Satellite Navigation Equipment. Please check the policy terms and conditions for each panel member or the “More Details “ button, next to the product pricing, for exact details of what is covered or excluded on each policy.</p>
                                            </div>
                                            : ""
                                    }
                                    {
                                        benefit.title === "Full-service history" ?
                                            <div id="service-history">
                                                <p>A full-service history is evidence that the vehicle has been maintained at every required interval since it was built.
                                                    The service history book should be completed as evidence.
                                                    By clicking 'Yes', you are confirming the vehicle either has a full-service history or will have been serviced in accordance with
                                                    the manufacturer's recommendations within the last twelve months.</p>
                                            </div>
                                            : ""
                                    }
                                </div>
                            )
                        }) : ""
                    }

                </div>
            </div>
        </div>





    );
};

export default BenefitsList;
